import Layout from "../../components/common/Layout"
import React from "react"
import Learn from "../../components/common/Learn"
import Footer from "../../components/common/Footer"
import Cursor from "../../components/common/Cursor"
import Kv from "../../components/section/careers/Kv"
import Us from "../../components/section/careers/Us"
import Team from "../../components/section/careers/Team"
import Recruitment from "../../components/section/careers/Recruitment"

export default function Careers() {
  let title = "CAREERS"
  let description =
    "CRISPだから、できる挑戦がある。外食を進化させるトップランナーへ。"
  let headTtl = "CAREERS"
  let blockTtl =
    'CRISPだから、できる挑戦がある。<br class="u-none__sp" />外食を進化させるトップランナーへ。'
  let blockTxt =
    'これからどんどん加速していく外食産業のDX。<br class="u-none__sp" />けれど今、それが本当の意味で実践できている企業はほとんどありません。DXを推し進め、新しいレストラン体験を創る。そのためなら、なんでも挑戦できるのがCRISPです。私たちの全員が、チャレンジャーであると同時に日本のトップランナーです。あなたの力で、私たちと一緒に新しい外食の未来をつくっていきましょう。'
  let dark = "js-scroll__dark"
  let kvSrc = "/asset/img/common/careers.jpg"
  let kvSrc2x = "/asset/img/common/careers@2x.jpg"
  let kvSrcWebp = "/asset/img/common/careers.jpg.webp"
  let kvSrcWebp2x = "/asset/img/common/careers@2x.jpg.webp"
  let kvSrcSp = "/asset/img/common/sp/careers_kv.jpg"
  let kvSrcWebpSp = "/asset/img/common/sp/careers_kv.jpg.webp"
  let kvAlt = "HIROKI WADA"

  return (
    <Layout title={title} description={description}>
      <div id="page">
        <div id="careers" className="js-page">
          <main id="main">
            <div id="contents" className="contents">
              <Cursor />
              <Kv
                headTtl={headTtl}
                blockTtl={blockTtl}
                blockTxt={blockTxt}
                dark={dark}
                kvSrc={kvSrc}
                kvSrc2x={kvSrc2x}
                kvSrcWebp={kvSrcWebp}
                kvSrcWebp2x={kvSrcWebp2x}
                kvSrcSp={kvSrcSp}
                kvSrcWebpSp={kvSrcWebpSp}
                kvAlt={kvAlt}
              />
              <Us />
              <Team />
              <Recruitment />
              <Learn backgroundColor={"gradation"} />
            </div>
          </main>
          <Footer />
        </div>
      </div>
    </Layout>
  )
}
