import React from "react"

const Us = () => {
  return (
    <section className="p-careers-us">
      <div className="p-careers-us__wrapper">
        <div className="p-contents p-contents__us">
          <div className="p-contents__wrapper">
            <h2 className="c-ttl__en p-contents__en">
              GET TO
              <br className="u-none__sp" />
              KNOW US
            </h2>
            <div className="p-contents__block">
              <h3 className="c-ttl__min p-contents__ttl">
                私たちの目指す未来や、大切にしていること。
                <br className="u-none__sp" />
                ここに、そのすべてを詰め込みました。
              </h3>
            </div>
          </div>
          <div className="js-scroll__dark__sp p-contents__img">
            {/*// <!-- <script async class="speakerdeck-embed" data-id="d0dd072e8ed345419433690c9ef3879d" data-ratio="1.77777777777778" src="//speakerdeck.com/assets/embed.js"></script> -->*/}
            <div className="p-contents__img-iframe-cover">
              <span className="p-contents__img-iframe-cover-border__vertical">
                <span className="p-contents__img-iframe-cover-border-inner" />
              </span>
              <span className="p-contents__img-iframe-cover-border__side">
                <span className="p-contents__img-iframe-cover-border-inner" />
              </span>
              {/*// <!--<iframe src="https://speakerdeck.com/player/d0dd072e8ed345419433690c9ef3879d?" style="top: 0; left: 0; width: 100%; height: 100%; position: absolute; border: 0;" allowfullscreen allow="encrypted-media;"></iframe>-->*/}
              <iframe
                title={"test"}
                src="https://docs.google.com/presentation/d/e/2PACX-1vRXrLmz4w8QzXjaJVYXiPQenOdA65qPJoqsZOerZMfAR-lF70yrg33vjyexmYE3QcP6isHH1rE1vqal/embed?start=false&loop=false&delayms=3000"
                className="p-contents__img-iframe"
                allowFullScreen
                allow="encrypted-media;"
                mozallowfullscreen="true"
                webkitallowfullscreen="true"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Us
