import React from "react"

const Team = () => {
  return (
    <section className="p-careers-team">
      <div className="p-careers-team__wrapper">
        <div className="p-contents p-contents__team">
          <div className="p-contents__wrapper">
            <h2 className="c-ttl__en p-contents__en">JOIN OUR TEAM</h2>
            <div className="p-contents__block">
              <h3 className="c-ttl__min p-contents__ttl">
                「非連続な成長」と「高い収益率」を生む、
                <br className="u-none__sp" />
                コネクティッド・レストランを目指して。
              </h3>
            </div>
          </div>
          <div className="p-contents__content">
            <p className="c-txt__main p-contents__content-txt">
              CRISPでは外食産業のDXを推し進める仕組みをつくる「THE
              FARM」、実際に現場の店舗にDXを実装してレストラン体験をつくる「RESTAURANT」、二つの領域で共に働くパートナーを募集しています。私たちが目指すのは、一人ひとりが専門性を活かし、パフォーマンスを最大限に発揮できるチームです。
            </p>
            <p className="c-txt__main p-contents__content-txt">
              あらゆる場所でリアルなつながりをつくる、これからのレストランを創造するために。接客、エンジニアリング、マーケティング、データサイエンス、デザインなどの様々なプロフェッショナル人材が、フィールドを超えて自由に協力し、どんなことにでも挑戦できる環境とカルチャーを用意しています。
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Team
