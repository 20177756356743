import React from "react"
import ArrowYDown from "../../common/ArrowYDown"
import Img from "../../common/Img"

const Kv = ({
  headTtl,
  blockTtl,
  blockTxt,
  dark,
  kvSrc,
  kvSrc2x,
  kvSrcWebp,
  kvSrcWebp2x,
  kvSrcSp,
  kvSrcWebpSp,
  kvAlt,
}) => {
  return (
    <section className="p-common-kv p-careers-kv">
      <div className="p-common-kv__wrapper">
        <div className="p-common-kv__head">
          <span className="p-common-kv__head__parent" />
          <h1 className="p-common-kv__head-ttl">{headTtl}</h1>
        </div>
        <div className="p-common-kv__block">
          <div className="p-common-kv__content">
            <h2 className="c-ttl__main p-common-kv__content-ttl">
              <div dangerouslySetInnerHTML={{ __html: blockTtl }} />
            </h2>
            <p
              className="c-txt__main p-common-kv__content-txt"
              dangerouslySetInnerHTML={{ __html: blockTxt }}
            />
          </div>
        </div>
        <button
          className="js-hover p-common-kv__arrow"
          data-updown-trigger="kv"
          data-hover-in-time="600"
          aria-label="スクロールダウンボタン"
        >
          <ArrowYDown />
        </button>
        <div className="p-top-careers__header">
          <div className="p-top-careers__header-content">
            <span className="u-none__sp p-top-careers__header-content-sub">
              CHIEF TECHNOLOGY OFFICER
            </span>
            <span className="u-none__pc p-top-careers__header-content-sub">
              CTO
            </span>
            <span className="p-top-careers__header-content-main">
              HIROKI WADA
            </span>
          </div>
          <div
            className={` ${dark} p-common-kv__img p-common-kv__img__careers `}
            data-updown-target="kv"
          >
            <Img
              src={kvSrc}
              src2x={kvSrc2x}
              webpSrc={kvSrcWebp}
              webpSrc2x={kvSrcWebp2x}
              spSrc={kvSrcSp}
              // webpSpSrc={kvSrcWebpSp}
              alt={kvAlt}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Kv
