import { Link } from "gatsby"
import React from "react"
import ArrowX from "../../common/ArrowX"
import Img from "../../common/Img"

const Recruitment = () => {
  return (
    <section className="p-careers-recruitment">
      <div className="p-careers-recruitment__wrapper">
        <ul className="p-careers-recruitment__list">
          <li
            className="js-hover p-careers-recruitment__item"
            data-hover-in-time="600"
            data-hover-out-time="650"
          >
            <Link
              to="/careers/farm/"
              className="p-careers-recruitment__item-link"
            >
              <div className="p-careers-recruitment__item-wrapper">
                <div className="js-scroll__dark c-link__img p-careers-recruitment__item-img">
                  <Img
                    src="/asset/img/careers/farm_kv.jpg"
                    src2x="/asset/img/careers/farm_kv@2x.jpg"
                    webpSrc="/asset/img/careers/farm_kv.jpg.webp"
                    webpSrc2x="/asset/img/careers/farm_kv@2x.jpg.webp"
                    alt="web開発の様子"
                    imgClassName="p-careers-recruitment__item-img-item"
                  />
                </div>
                <div className="p-careers-recruitment__item-content">
                  <h3 className="p-careers-recruitment__item-content-en">
                    THE FARM
                  </h3>
                  <h4 className="p-careers-recruitment__item-content-ttl">
                    テクノロジーを駆使し、
                    <br className="u-none__sp" />
                    新しいレストラン体験を創造する。
                  </h4>
                  <p className="c-txt__main p-careers-recruitment__item-content-txt">
                    CRISPでは、テクノロジーを最大限に活用した外食の進化に本気で取り組んでいます。そのため「THE
                    FARM」のパートナーに求められるのは、自分の専門性を活かして外食のDXを前に推し進めていくという強いチャレンジ精神です。店舗運営だけではなく、アプリやSaaSの開発、デザイン、顧客情報のデータ化やそれに基づくマーケティングまで、多種多様なプロフェッショナルの活躍できる場が揃っています。
                  </p>
                </div>
              </div>
              <div className="p-careers-recruitment__item-footer">
                <div className="c-link__main p-careers-recruitment__item-footer-wrapper">
                  <span className="p-careers-recruitment__item-footer-span">
                    <span className="c-link__main-inner">THE FARMの募集</span>
                    <ArrowX />
                  </span>
                </div>
              </div>
            </Link>
          </li>
          <li
            className="js-hover p-careers-recruitment__item"
            data-hover-in-time="600"
            data-hover-out-time="650"
          >
            <Link
              to="/careers/restaurant/"
              className="p-careers-recruitment__item-link"
            >
              <div className="p-careers-recruitment__item-wrapper">
                <div className="js-scroll__dark c-link__img p-careers-recruitment__item-img">
                  <Img
                    src="/asset/img/careers/restaurant_kv.jpg"
                    src2x="/asset/img/careers/restaurant_kv@2x.jpg"
                    webpSrc="/asset/img/careers/restaurant_kv.jpg.webp"
                    webpSrc2x="/asset/img/careers/restaurant_kv@2x.jpg.webp"
                    alt="店内で働くスタッフ"
                    imgClassName="p-careers-recruitment__item-img-item"
                  />
                </div>
                <div className="p-careers-recruitment__item-content">
                  <h3 className="p-careers-recruitment__item-content-en">
                    RESTAURANT
                  </h3>
                  <h4 className="p-careers-recruitment__item-content-ttl">
                    接客を次のステージへ。
                    <br className="u-none__sp" />
                    新しいレストラン体験を実装する。
                  </h4>
                  <p className="c-txt__main p-careers-recruitment__item-content-txt">
                    私たちにとって一番大切なことは、店舗での体験価値を上げることです。そのためにデータやテクノロジーも積極的に取り入れています。「RESTAURANT」のパートナーに求められるのは、どうしたらもっと良い顧客体験を提供できるかを考え、実行できる人。例えば、お客様のLTVを10倍にするためなら、データに基づいて既存のルールを越えた判断と実践ができる。そんな意思と現場経験を持っている方には最適な環境です。
                  </p>
                </div>
              </div>
              <div className="p-careers-recruitment__item-footer">
                <div className="c-link__main p-careers-recruitment__item-footer-wrapper">
                  <span className="p-careers-recruitment__item-footer-span">
                    <span className="c-link__main-inner">RESTAURANTの募集</span>
                    <ArrowX />
                  </span>
                </div>
              </div>
            </Link>
          </li>
        </ul>
      </div>
    </section>
  )
}

export default Recruitment
